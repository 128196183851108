export default {
    gestion: {
        pei:{
            carte: {
                iconText: "{k} {volume_pa} {acces_4x4}"
            },
            plan: "Coordonnées DFCI",
            anomaly_state: {
                b: 'Anomalies bloquantes <small>(Pour toutes anomalies bloquantes, veuillez ajouter un commentaire)</small>'
            },
            type_aspiration: 'Type de point d\'aspiration',
            last_technical_control: {
              volume_pa: 'Volume point aspiration',
            },
          simultaneousControls: {
            debit: "Débit du sous 1 bar du contrôle",
          },
        },
        controlessimultanes: {
          debit: "Débit sous 1 bar",
        },
    },
    administration: {
        configuration: {
            coverDistanceColor: 'PiYG',
        },
    },
    notifications: {
        territory_types_recipients: {
            exploitant: "Exploitant",
        }
    },
    legend: {
        OSM: "OpenStreetMap",
        SCAN25: "Scan 25 IGN",
        ORTHO: "Photo aérienne IGN",
        PLANIGNV2: "Plan IGN",
    }
}
