export default {
    permission: {
        admin: "Administrateur",
        aep: 'AEP',
        simple: 'Simple',
        alert_index: "Lister les alertes",
        alert_show: "Afficher une alerte",
        anomaly_index: "Lister les anomalies",
        availableLayer_index: "Lister les couches disponibles",
        availableLayer_show: "Afficher une couche disponible",
        changeLog_page: "Voir la page des journaux de modification",
        changeLog_index: "Lister les journaux de modification",
        collectivity: 'Collectivité',
        commune_destroy: "Supprimer une commune",
        commune_index: "Lister les communes",
        commune_list: "Accéder au filtre commune",
        commune_show: "Afficher une commune",
        commune_store: "Créer une commune",
        commune_update: "Mettre à jour une commune",
        communes_display_all: 'Peux afficher les PEIs des autres territoires',
        config_show: "Afficher la configuration",
        config_update: "Mettre à jour la configuration",
        emailTemplate_destroy: "Supprimer un modèle d'email",
        emailTemplate_index: "Lister les modèles d'email",
        emailTemplate_page: "Accéder à la gestion des modèles d'email",
        emailTemplate_show: "Afficher un modèle d'email",
        emailTemplate_store: "Créer un modèle d'email",
        emailTemplate_update: "Mettre à jour un modèle d'email",
        guest: 'Non connecté',
        importPei_page: "Accéder à la page d'import de fichier PEI",
        importPei_create: "Créer une importation de PEI",
        importPei_destroy: "Supprimer une importation de PEI",
        importPei_display_all: "Afficher toutes les importations PEI",
        importPei_download: "Télécharger une importation de PEI",
        importPei_index: "Lister les importations de PEI",
        importPei_show: "Afficher l'importation de PEI",
        importPei_store: "Enregistrer une importation de PEI",
        importPei_update: "Mettre à jour une importation de PEI",
        ldap_import: "Import des utilisateurs depuis le serveur LDAP",
        manager: 'Gestionnaire SDIS',
        mobile_app_CT: "Application mobile CT",
        mobile_app_RO: "Application mobile RO",
        notificationConfig_index: "Lister les configurations de notification",
        notificationConfig_show: "Afficher une configuration de notification",
        notificationConfig_update: "Mettre à jour une configuration de notification",
        operationalRecognition_index: "Lister les reconnaissances opérationnelles",
        operationalRecognition_show: "Afficher une reconnaissance opérationnelle",
        operationalRecognition_store: "Créer une reconnaissance opérationnelle",
        operationalRecognition_read_controllerName: "Afficher le nom du controleur RO",
        peiComment_destroy: "Supprimer un commentaire PEI",
        peiComment_index: "Lister les commentaires PEI",
        peiComment_show: "Afficher un commentaire PEI",
        peiComment_store: "Créer un commentaire PEI",
        peiComment_update: "Mettre à jour un commentaire PEI",
        peiDeletionDemand_deny: "Refuser une demande de suppression PEI",
        peiDeletionDemand_destroy: "Supprimer une demande de suppression PEI",
        peiDeletionDemand_index: "Lister les demandes de suppression PEI",
        peiDeletionDemand_show: "Afficher une demande de suppression PEI",
        peiDeletionDemand_store: "Créer une demande de suppression PEI",
        peiDeletionDemand_update: "Mettre à jour une demande de suppression PEI",
        peiDocument_destroy: "Supprimer un document PEI",
        peiDocument_download: "Télécharger un document PEI",
        peiDocument_getThumbnail: "Obtenir la vignette d'un document PEI",
        peiDocument_index: "Lister les documents PEI",
        peiDocument_show: "Afficher un document PEI",
        peiDocument_store: "Créer un document PEI",
        peiDocument_update: "Mettre à jour un document PEI",
        pei_covers: "Couvertures PEI",
        pei_write_numeroCourt: "Modifier le numéro court des PEI",
        pei_write_numeroPrefix: "Modifier le prefixe du numero long des PEI",
        pei_destroy: "Supprimer un PEI",
        pei_destroy_all: "Supprimer tous les PEI",
        pei_display_all: "Afficher tous les PEI",
        pei_edit_all: "Éditer tous les PEI",
        pei_page: "Voir la page des PEI",
        pei_index: "Lister les PEI",
        pei_project_edit: "Éditer un projet PEI",
        pei_project_show: "Afficher un projet PEI",
        pei_project_store: "Créer un projet PEI",
        pei_read_activeDeletionDemandsCount: "Lire le nombre de demandes de suppression actives PEI",
        pei_read_dateTestAspiration: "Lire la date du test d'aspiration PEI",
        pei_read_deletionDemands: "Lire les demandes de suppression PEI",
        pei_read_samplingTours: "Lire les tournées d'échantillonnage PEI",
        pei_read_typeAspiration: "Lire le type d'aspiration PEI",
        pei_read_zoneTour: "Lire la zone de tournée PEI",
        pei_read_zoneTourId: "Lire l'ID de la zone de tournée PEI",
        pei_restore: "Restaurer un PEI",
        pei_show: "Afficher un PEI",
        pei_store: "Créer un PEI",
        pei_update: "Mettre à jour un PEI",
        pei_update_all: "Mettre à jour tous les PEI",
        pei_write_dateTestAspiration: "Modifier la date du test d'aspiration PEI",
        pei_write_lastOperationalRecognition: "Modifier la dernière reconnaissance opérationnelle PEI",
        pei_write_lastTechnicalControl: "Modifier le dernier contrôle technique PEI",
        pei_write_typeAspiration: "Modifier le type d'aspiration PEI",
        processing_index: "Voir la liste des exports (N'est pas visible sur l'interface Hydraclic)",
        processing_show: "Télécharger un export",
        processing_update: "Demander que l'export soit transmis par e-mail",
        profile_show: "Afficher un profil",
        profile_update: "Mettre à jour un profil",
        resource_page: "Voir la page des ressources",
        resource_index: "Lister les ressources",
        role_index: "Lister les rôles",
        sentEmail_page: "Voir la page des emails envoyés",
        sentEmail_index: "Lister les emails envoyés",
        sentEmail_show: "Afficher un email envoyé",
        sentEmail_store: "Enregistrer un email envoyé",
        simultaneousControl_destroy: "Supprimer un contrôle simultané",
        simultaneousControl_page: "Voir la page des contrôles simultanés",
        simultaneousControl_index: "Lister les contrôles simultanés",
        simultaneousControl_show: "Afficher un contrôle simultané",
        simultaneousControl_store: "Créer un contrôle simultané",
        simultaneousControl_update: "Mettre à jour un contrôle simultané",
        stakeholderDocument_destroy: "Supprimer un document d'un acteur",
        stakeholderDocument_download: "Télécharger un document d'un acteur",
        stakeholderDocument_getThumbnail: "Obtenir la vignette d'un document d'un acteur",
        stakeholderDocument_index: "Lister les documents d'un acteur",
        stakeholderDocument_show: "Afficher un document d'un acteur",
        stakeholderDocument_store: "Créer un document pour un acteur",
        stakeholderDocument_update: "Mettre à jour un document d'un acteur",
        stakeholder_destroy: "Supprimer un acteur",
        stakeholder_page: "Voir la page des acteurs",
        stakeholder_index: "Lister les acteurs",
        stakeholder_show: "Afficher un acteur",
        stakeholder_store: "Créer un acteur",
        stakeholder_update: "Mettre à jour un acteur",
        stats_show: "Voir le tableau de bord",
        stats_operational_recognition: "Statistiques des reconnaissances opérationnelles",
        stats_pei_no_owner: "Statistiques des PEI sans propriétaire",
        stats_pei_state: "Statistiques des états de PEI",
        stats_pei_status: "Statistiques des statuts de PEI",
        stats_pei_type: "Statistiques des types de PEI",
        stats_technical_control: "Statistiques des contrôles techniques",
        technicalControl_index: "Lister les contrôles techniques",
        technicalControl_show: "Afficher un contrôle technique",
        technicalControl_store: "Créer un contrôle technique",
        technicalControl_read_controllerName: "Afficher le nom du contrôleur CT",
        territoryDocument_destroy: "Supprimer un document de territoire",
        territoryDocument_download: "Télécharger un document de territoire",
        territoryDocument_getThumbnail: "Obtenir la vignette d'un document de territoire",
        territoryDocument_index: "Lister les documents de territoire",
        territoryDocument_show: "Afficher un document de territoire",
        territoryDocument_store: "Créer un document de territoire",
        territoryDocument_update: "Mettre à jour un document de territoire",
        territory_destroy: "Supprimer un territoire",
        territory_page: "Voir la page des territoires",
        territory_index: "Lister les territoires",
        territory_list: "Accéder au filtre Territoire",
        territory_read_emails: "Lire les emails d'un territoire",
        territory_show: "Afficher un territoire",
        territory_store: "Créer un territoire",
        territory_update: "Mettre à jour un territoire",
        territory_write_emails: "Modifier des emails pour un territoire",
        tour_destroy: "Supprimer une tournée",
        tour_list: "Accéder au filtre tournée",
        tour_index: "Lister les tournées",
        tour_page: "Accéder à la page des tournées",
        tour_show: "Afficher une tournée",
        tour_store: "Créer une tournée",
        tour_update: "Mettre à jour une tournée",
        unavailability_destroy: "Supprimer une indisponibilité",
        unavailability_page: "Voir la page des indisponibilités",
        unavailability_index: "Lister les indisponibilités",
        unavailability_show: "Afficher une indisponibilité",
        unavailability_store: "Créer une indisponibilité",
        unavailability_update: "Mettre à jour une indisponibilité",
        user: 'Utilisateur SDIS',
        user_destroy: "Supprimer un utilisateur",
        user_page: "Voir la page des utilisateurs",
        user_index: "Lister les utilisateurs",
        user_restore: "Restaurer un utilisateur",
        user_show: "Afficher un utilisateur",
        user_store: "Créer un utilisateur",
        user_update: "Mettre à jour un utilisateur",
        user_write_name: "Modifier le nom de l'utilisateur",
        user_write_username: "Modifier l'identifiant de l'utilisateur ",
        pei_full_geojson: "Accéder au flux geojson des PEI"
    }
}
