export default {
    actions: 'Actions',
    colors: {
        success: 'Succès',
        warning: 'Avertissement',
        error: 'Erreur',
        info: 'Information',
    },
    created_at: 'Date de création',
    created_by: 'Créé par',
    credentials: {
        created_at: 'Date de création',
        updated_at: 'Date de modification',
        created_by: {
            name: 'Créé par',
        },
        updated_by: {
            name: 'Modifié par',
        },
    },
    documents: {
        index: 'Documents',
        delete: {
            index: 'Supprimer',
            title: 'Suppression d\'un document',
            text: 'Le document {name} va être supprimé définitivement. <br/> Souhaitez-vous continuer ?',
        },
        toaster: {
            POST: {
                success: {
                    title: 'Documents',
                    text: 'Le document {name} a bien été créé',
                },
            },
            DELETE: {
                success: {
                    title: 'Documents',
                    text: 'Le document {name} a été supprimé',
                },
            },
        },
    },

    download: 'Télécharger',
    errors: {
        basic: 'Une erreur est survenue',
        request: 'La récupération des données a échouée. <br> Essayez de raffraichir la page ou de contacter un administrateur.',
    },
    forms: {
        validations: {
            required: 'Champ obligatoire',
            email: 'Doit être un e-mail',
            integer: 'Doit être un nombre entier',
            numeric: 'Doit être une valeur numérique',
            maxLength: 'Longueur maximum : {value}',
            minLength: 'Longueur minimale : {value}',
            maxValue: 'Valeur maximale : {value}',
            minValue: 'Valeur minimale : {value}',
            regex: 'Le champ saisi n\'est pas conforme',
        },
    },
    layers: {
        code: 'Code',
        url: 'URL',
        base_layer: 'Fond de plan',
        default_layer: 'Affiché par défaut',
    },
    operators: {
        eq: '=',
        lt: '<',
        lte: '≤',
        gt: '>',
        gte: '≥',
    },
    updated_by: 'Modifié par',
}
