import lang_fr from './lang/fr';
import lang_fr17 from './lang/17';
import lang_fr81 from './lang/81';
import lang_fr84 from './lang/84';
import lang_fr82 from './lang/82';

import library_fr from './packages/datakode/nuxt-datakode-utils/lang/fr';
import permissions_fr from './lang/permissions.fr';

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deepMerge(obj1, obj2) {
    const defaultLang =  {...obj1}
    for (const key in obj2) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj2.hasOwnProperty(key)) {
            if (obj2[key] instanceof Object && defaultLang[key] instanceof Object) {
                defaultLang[key] = deepMerge(defaultLang[key], obj2[key]);
            } else {
                defaultLang[key] = obj2[key];
            }
        }
    }
    return defaultLang;
}

// eslint-disable-next-line prefer-const
let lang= {
    ...lang_fr,
    ...permissions_fr,
    ...library_fr,
}

export default defineI18nConfig(() => ({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    warnHtmlMessage: false,
    runtimeOnly: false,
    messages: {
        fr: lang,
        "17": deepMerge(lang, lang_fr17),
        "81": deepMerge(lang, lang_fr81),
        "82": deepMerge(lang, lang_fr82),
        "84": deepMerge(lang, lang_fr84),
    }
}))
